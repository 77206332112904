
.position-toggle[data-v-8c55fa04] {
  margin-left: 1em;
}
.v-btn.position-filter-active[data-v-8c55fa04] {
  background-color: var(--v-primary_button-base);
}
.player-list[data-v-8c55fa04] th[role="columnheader"] {
  white-space: nowrap;
}

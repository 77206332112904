
.table td[data-v-6b161281] {
  padding-bottom: 0.5em;
}
.position[data-v-6b161281] {
  width: 25%;
}
.count[data-v-6b161281] {
  min-width: 8em;
  width: 8em;
}
.description[data-v-6b161281] {
  padding-left: 1em;
  font-size: small;
}


.roster-name[data-v-9d8e46b0] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.matchup td.roster[data-v-9d8e46b0] {
  width: 35%;
}
.matchup td.score[data-v-9d8e46b0] {
  width: 10%;
}
.matchup td[data-v-9d8e46b0] {
  border-top: 1px solid var(--bg-color-secondary);
}
.roster-away[data-v-9d8e46b0] {
  text-align: left;
}
.score-away[data-v-9d8e46b0] {
  text-align: right;
  vertical-align: middle;
  padding-right: 1em;
  border-right: 1px solid var(--bg-color-secondary);
}
.roster-home[data-v-9d8e46b0] {
  text-align: right;
}
.score-home[data-v-9d8e46b0] {
  padding-left: 1em;
  vertical-align: middle;
  border-left: 1px solid var(--bg-color-secondary);
}
.record[data-v-9d8e46b0] {
  font-size: small;
}
td.vs[data-v-9d8e46b0] {
  vertical-align: middle;
  padding-left: 1em;
  padding-right: 1em;
}

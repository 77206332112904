
.saved[data-v-0c6e8178] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: green;
}
.fade-enter-active[data-v-0c6e8178] {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.fade-enter[data-v-0c6e8178] {
  opacity: 0;
}

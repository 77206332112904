
option.ideal[data-v-0341152e] {
  font-weight: bold;
}
.table[data-v-0341152e] {
  margin-top: 2em;
}
.table th[data-v-0341152e],
.table td[data-v-0341152e] {
  padding: 0.5em;
}
.table tr[data-v-0341152e]:nth-child(even) {
  background-color: var(--bg-color-secondary);
}

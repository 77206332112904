
.scoreboard[data-v-1d598278] {
  float: right;
  color: var(--text-color);
}
.score .away td[data-v-1d598278] {
  padding-top: 1em;
}
.score .home td[data-v-1d598278] {
  border-bottom: 1px solid var(--bg-color-secondary);
  padding-bottom: 1em;
}
.score:last-child .home td[data-v-1d598278] {
  border-bottom: none;
}
.score .pts[data-v-1d598278] {
  text-align: right;
  border-right: 1px solid var(--bg-color-secondary);
  padding-right: 1em;
}
.score .team[data-v-1d598278] {
  width: 8em;
}
.score .won[data-v-1d598278] {
  font-weight: 500;
}
.score .caption[data-v-1d598278] {
  padding-left: 1em;
  padding-right: 1em;
}

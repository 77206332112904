
.nav-primary[data-v-03924e14] * {
  color: rgba(255, 255, 255, 0.5);
}
.nav-secondary[data-v-03924e14] {
  color: rgba(255, 255, 255, 0.5);
}
.v-list-group[data-v-03924e14] .v-icon {
  color: rgba(255, 255, 255, 0.5);
}


.roster[data-v-2e59de04] {
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--bg-color-secondary);
  border-radius: 8px;
  background-color: var(--bg-color-secondary);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.budget .v-input__control[data-v-2e59de04] {
  width: 5em;
}


.snake-draft-picks[data-v-749b4280] {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 200px;
}
.snake-draft-picks[data-v-749b4280] .v-card__text {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: auto;
}


.v-btn[data-v-055255f4] {
  width: 20em;
}
.v-btn[data-v-055255f4] .v-btn__content {
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.v-btn.google[data-v-055255f4] {
  background-color: #dd4b39;
}
.v-btn.facebook[data-v-055255f4] {
  background-color: #3b5998;
}
.v-btn.twitter[data-v-055255f4] {
  background-color: #55acee;
}


.roster-name,
.trim {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-btn {
  text-transform: none !important;
  font-weight: normal !important;
}
.v-btn--active::before {
  opacity: 0 !important;
}
.v-btn--active:hover::before {
  opacity: 0.18 !important;
}
.v-card__title {
  word-break: unset;
}
.link {
  cursor: pointer;
}
